import { create } from "zustand";
import { TenantApiClient } from 'providers/TenantApiClient';

const userStore = (set, get) => ({
    // Initial state
    email: "",
    id: "",
    mobilePhone: "",
    firstName: "",
    lastName: "",
    roles: [],
    status: "",
    title: null,
    name: "",
    loading: false,
    error: null,

    // Fetch user data
    getUser: async () => {
        try {
            set({ loading: true, error: null });
            const response = await TenantApiClient.get('/api/user');
            const userData = response.data;

            // Parse the name into firstName and lastName
            const fullName = userData?.name || '';
            const nameParts = fullName.trim().split(' ');
            const firstName = nameParts[0] || '';
            const lastName = nameParts.slice(1).join(' ') || '';

            // Set the user data with parsed name
            set({
                email: userData?.email || "",
                id: userData?.id || "",
                mobilePhone: userData?.mobilePhone || "",
                firstName: firstName,
                lastName: lastName,
                roles: userData?.roles || [],
                status: userData?.status || "",
                title: userData?.title || null,
                name: userData?.name || "",
                loading: false
            });
        } catch (error) {
            set({
                error: error.message,
                loading: false
            });
            throw error;
        }
    },

    // Set user action
    setUser: (user) => {
        // Parse the name into firstName and lastName
      

        set({
            email: user?.email || "",
            id: user?.id || "",
            mobilePhone: user?.mobilePhone || "",
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            roles: user?.roles || [],
            status: user?.status || "",
            title: user?.title || null,
            name: user?.name || "",
            error: null
        });
    },

    // Clear user action
    clearUser: () => {
        set({
            email: "",
            id: "",
            mobilePhone: "",
            firstName: "",
            lastName: "",
            roles: [],
            status: "",
            title: null,
            name: "",
            error: null
        });
    }
});

const useUserStore = create(userStore);

export default useUserStore;