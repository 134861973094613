import {
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    TableCellLayout,
    makeStyles,
    tokens,
    Button,
    Card,
    Text,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    DialogActions,
    Input,
    Label,
    Field,
    Select,
} from "@fluentui/react-components";
import React, { useMemo, useState, useCallback } from "react";
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import useTenantStore from 'stores/tenantStore';
import {
    PersonRegular,
    MailRegular,
    PhoneRegular,
    ShieldLockRegular,
    EditRegular,
    DeleteRegular
} from "@fluentui/react-icons";

const useStyles = makeStyles({
    container: {
        padding: "24px",
        maxWidth: "1200px",
        margin: "0 auto",
    },
    header: {
        marginBottom: "24px",
    },
    headerTitle: {
        fontSize: "24px",
        fontWeight: "600",
        color: tokens.colorNeutralForeground1,
        marginBottom: "8px",
    },
    headerSubtitle: {
        color: tokens.colorNeutralForeground2,
    },
    rolesBadgeContainer: {
        display: "flex",
        gap: "4px",
        flexWrap: "wrap",
    },
    roleBadge: {
        backgroundColor: tokens.colorBrandBackground2,
        color: tokens.colorBrandForeground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
        textTransform: "capitalize",
    },
    dataGrid: {
        backgroundColor: tokens.colorNeutralBackground1,
        border: `1px solid ${tokens.colorNeutralStroke1}`,
        borderRadius: "8px",
        overflow: "hidden",
    },
    gridHeader: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    actionButton: {
        minWidth: "32px",
        height: "32px",
        padding: "0 8px",
    },
    actionButtonsContainer: {
        display: "flex",
        gap: "8px",
    },
    statusActive: {
        color: tokens.colorPaletteGreenForeground1,
        backgroundColor: tokens.colorPaletteGreenBackground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
    },
    statusInactive: {
        color: tokens.colorPaletteRedForeground1,
        backgroundColor: tokens.colorPaletteRedBackground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
    },
    statusPending: {
        color: tokens.colorPaletteYellowForeground1,
        backgroundColor: tokens.colorPaletteYellowBackground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
    },
    formField: {
        marginBottom: "16px",
    }
});

export default function TenantUsers() {
    const styles = useStyles();
    const tenantStore = useTenantStore();
    const messageStore = useMessageStore();
    const [editingUser, setEditingUser] = useState(null);
    const [deletingUser, setDeletingUser] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    // Handle edit user
    const handleEditClick = useCallback((user) => {
        setEditingUser({ ...user });
        setShowEditDialog(true);
    }, []);

    // Handle delete user
    const handleDeleteClick = useCallback((user) => {
        setDeletingUser(user);
        setShowDeleteDialog(true);
    }, []);

    // Handle save changes
    const handleSaveChanges = useCallback(async () => {
        if (!editingUser) return;
        try {
            // Changed to match the /api/Members/info endpoint
            await TenantApiClient.post('/api/Members/info', {
                Id : editingUser.id,
                Name: `${editingUser.firstName} ${editingUser.lastName}`,
                firstName: editingUser.firstName,
                lastName: editingUser.lastName,
                roles: editingUser.roles,
                MobilePhone: editingUser.mobilePhone,
                profileImage: editingUser.profileImage
            });

            messageStore.addMessage('Success', 'User updated successfully');
            setShowEditDialog(false);

            // Refresh tenant data 
            const updatedTenant = await TenantApiClient.get('/api/tenants/info');
            tenantStore.setTenant(updatedTenant);
        } catch (error) {
            messageStore.addAxiosError('Failed to update user', error);
        }
    }, [editingUser, tenantStore.id]);

    // Handle delete confirmation
    const handleDeleteConfirm = useCallback(async () => {
        if (!deletingUser) return;
        try {
            await TenantApiClient.put(`/api/Members/remove/${deletingUser.id}`);
            messageStore.addMessage('Success', 'User deleted successfully');
            setShowDeleteDialog(false);
            // Refresh tenant data
            const updatedTenant = await TenantApiClient.get('/api/tenants/info');
            tenantStore.setTenant(updatedTenant);
        } catch (error) {
            messageStore.addAxiosError('Failed to delete user', error);
        }
    }, [deletingUser, tenantStore.id]);

    const columns = useMemo(() => [
        createTableColumn({
            columnId: "firstName",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <PersonRegular />
                    Name
                </TableCellLayout>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <div>
                        <div>{`${item.firstName} ${item.lastName}`}</div>
                        {item.title && (
                            <div style={{ fontSize: "12px", color: tokens.colorNeutralForeground2 }}>
                                {item.title}
                            </div>
                        )}
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "email",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <MailRegular />
                    Email
                </TableCellLayout>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <Text>{item.email}</Text>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "mobilePhone",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <PhoneRegular />
                    Phone
                </TableCellLayout>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <Text>{item.mobilePhone || "-"}</Text>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "roles",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <ShieldLockRegular />
                    Roles
                </TableCellLayout>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <div className={styles.rolesBadgeContainer}>
                        {item.roles?.map((role, index) => (
                            <span key={index} className={styles.roleBadge}>
                                {role}
                            </span>
                        ))}
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "status",
            renderHeaderCell: () => "Status",
            renderCell: (item) => (
                <TableCellLayout>
                    <span className={
                        item.status === "Active" ? styles.statusActive :
                            item.status === "Pending" ? styles.statusPending :
                                styles.statusInactive
                    }>
                        {item.status || "Inactive"}
                    </span>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "actions",
            renderHeaderCell: () => "Actions",
            renderCell: (item) => (
                <TableCellLayout>
                    <div className={styles.actionButtonsContainer}>
                        <Button
                            icon={<EditRegular />}
                            appearance="subtle"
                            className={styles.actionButton}
                            title="Edit user"
                            onClick={() => handleEditClick(item)}
                        />
                        <Button
                            icon={<DeleteRegular />}
                            appearance="subtle"
                            className={styles.actionButton}
                            title="Delete user"
                            onClick={() => handleDeleteClick(item)}
                        />
                    </div>
                </TableCellLayout>
            ),
        }),
    ], [styles, handleEditClick, handleDeleteClick]);

    return (
        <div>
            <div className={styles.header}>
                <Text className={styles.headerTitle}>Tenant Users</Text>
            </div>

            <Card>
                <DataGrid
                    items={tenantStore.tenantUsers}
                    columns={columns}
                    sortable
                    getRowId={item => item.id}
                    className={styles.dataGrid}
                >
                    <DataGridHeader className={styles.gridHeader}>
                        <DataGridRow>
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>
                                    {renderHeaderCell()}
                                </DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody>
                        {({ item, rowId }) => (
                            <DataGridRow key={rowId}>
                                {({ renderCell }) => (
                                    <DataGridCell>
                                        {renderCell(item)}
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            </Card>

            {/* Edit User Dialog */}
            <Dialog open={showEditDialog} onOpenChange={(e, data) => setShowEditDialog(data.open)}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Edit User</DialogTitle>
                        <DialogContent className={styles.dialogContent}>
                            <Field required label="First Name" className={styles.formField}>
                                <Input
                                    value={editingUser?.firstName || ''}
                                    onChange={(e) => setEditingUser(prev => ({ ...prev, firstName: e.target.value }))}
                                />
                            </Field>
                            <Field required label="Last Name" className={styles.formField}>
                                <Input
                                    value={editingUser?.lastName || ''}
                                    onChange={(e) => setEditingUser(prev => ({ ...prev, lastName: e.target.value }))}
                                />
                            </Field>
                            <Field label="Email" className={styles.formField}>
                                <Input
                                    value={editingUser?.email || ''}
                                    disabled
                                />
                            </Field>
                            <Field label="Phone" className={styles.formField}>
                                <Input
                                    value={editingUser?.mobilePhone || ''}
                                    onChange={(e) => setEditingUser(prev => ({ ...prev, mobilePhone: e.target.value }))}
                                />
                            </Field>
                            <Field label="Role" className={styles.formField}>
                                <Select
                                    value={editingUser?.roles?.[0] || ''}
                                    onChange={(e, data) => setEditingUser(prev => ({ ...prev, roles: [data.value] }))}
                                >
                                    <option value="owner">Owner</option>
                                    <option value="agent">Agent</option>
                                    <option value="admin">Admin</option>
                                </Select>
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="secondary" onClick={() => setShowEditDialog(false)}>
                                Cancel
                            </Button>
                            <Button appearance="primary" onClick={handleSaveChanges}>
                                Save Changes
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={showDeleteDialog} onOpenChange={(e, data) => setShowDeleteDialog(data.open)}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Delete User</DialogTitle>
                        <DialogContent>
                            Are you sure you want to delete {deletingUser?.firstName} {deletingUser?.lastName}? This action cannot be undone.
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="secondary" onClick={() => setShowDeleteDialog(false)}>
                                Cancel
                            </Button>
                            <Button appearance="primary" onClick={handleDeleteConfirm}>
                                Delete
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
}