import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { TenantApiClient } from 'providers/TenantApiClient';
import {
    Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell,
    DataGridRow, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, mergeClasses, Tab,
    TableCellLayout, TabList, tokens, Card, Caption1, Avatar, Tooltip, Spinner, Dropdown, Option
} from "@fluentui/react-components";
import {
    MoreVerticalRegular,
    EditRegular,
    DeleteRegular,
    PeopleCheckmarkRegular,
    SendRegular,
    SendFilled,
    ArrowSyncRegular,
    DocumentPdfRegular,  
    PersonRegular,       
    Alert28Regular
} from "@fluentui/react-icons";
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import useMessageStore from 'stores/messageStore';
import useTenantStore from "stores/tenantStore";
import ShortcutCard from 'components/Shortcut';
import CreateApplicationDialog from '../dialogs/CreateApplication';
import AddMemberDialog from '../dialogs/AddAgent';
import AssignApplicationDialog from '../dialogs/AssignApplication';
import DeleteApplicationDialog from '../dialogs/DeleteApplication';
import RequestFollowUpDialog from '../dialogs/RequestFollowUp';
import finicityLogo from '../assets/images/logos/finicity-logo.png';
import plaidLogo from '../assets/images/logos/plaid-logo.png';
import backgroundImage from '../assets/images/backgrounds/crm-dark.png';
import backgroundImage2 from '../assets/images/backgrounds/crm-light.png';
import useUserStore from 'stores/userStore';
import ActivityLog from './Admin/Tabs/ActivityLog';


Chart.register(ArcElement, ChartTooltip, Legend);

const STATUS_OPTIONS = [
    { value: 'Created', label: 'Created' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Started', label: 'Started' },
    { value: 'WaitingForApplicant', label: 'Waiting for Applicant' },
    { value: 'Processing', label: 'Processing' },
    { value: 'Ready', label: 'Ready' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Funded', label: 'Funded' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Canceled', label: 'Canceled' }
];


const getStatusColor = (status) => {
    const statusColors = {
        Created: { bg: '#00B5D8', text: '#FFFFFF' },  // Strong Cyan
        Pending: { bg: '#3182CE', text: '#FFFFFF' },  // Deep Blue
        Started: { bg: '#2B6CB0', text: '#FFFFFF' },  // Stronger Blue
        WaitingForApplicant: { bg: '#D69E2E', text: '#FFFFFF' },  // Deep Gold
        Processing: { bg: '#6C757D', text: '#FFFFFF' },  // Dark Grey
        Ready: { bg: '#38A169', text: '#FFFFFF' },  // Strong Green
        Approved: { bg: '#228B22', text: '#FFFFFF' },  // Forest Green
        Funded: { bg: '#1F9254', text: '#FFFFFF' },  // Rich Green
        Rejected: { bg: '#E53E3E', text: '#FFFFFF' },  // Strong Red
        Declined: { bg: '#C53030', text: '#FFFFFF' },  // Dark Red
        Canceled: { bg: '#718096', text: '#FFFFFF' }   // Cool Grey
    };

    return statusColors[status] || { bg: '#EDF2F7', text: '#4A5568' };
};

const useStyles = makeStyles({

        // Layout & Container styles
        root: {
            padding: '24px',
            minHeight: '100vh',
            backgroundImage: `url(${backgroundImage2})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            '@media (max-width: 768px)': {
                padding: '16px 12px',
            }
        },

        // Stats Grid & Cards
        statsContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '16px',
            marginBottom: '24px',
            '@media (max-width: 1400px)': {
                gridTemplateColumns: 'repeat(2, 1fr)',
            },
            '@media (max-width: 768px)': {
                gridTemplateColumns: '1fr',
            }
    },
    pieChartContainer: {
        position: 'relative',
        height: 'calc(100% - 40px)',  // Account for title
        marginTop: '16px'
    },
    statsCard: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '24px',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        marginBottom: '12px'
    },
    mainValue: {
        fontSize: '36px',
        fontWeight: 600,
        color: '#0078D4',
        marginBottom: '16px'
    },
    metricItem: {
        fontSize: '14px',
        color: '#605E5C',
        marginBottom: '8px'
    },
    shortcutCardWrapper: {
        marginTop: 'auto',
        paddingTop: '16px',
        borderTop: '1px solid #E8E8E8'
    },
      

        // Notifications Card
        notificationsCard: {
            backgroundColor: '#fff',
            borderRadius: '8px',
            height: '180px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        },
    notificationTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '16px 16px 8px 16px',
        fontSize: '14px',
        color: '#242424'
    },
  notificationsWrapper: {
                height: 'calc(100% - 40px)',  // Account for title
                overflowY: 'auto',
                marginTop: '16px',
                '&::-webkit-scrollbar': {
                    width: '4px'
                },
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '4px'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '4px'
                }
            },
        notificationItem: {
            padding: '8px 16px',
            borderTop: '1px solid #f0f0f0',
            fontSize: '14px'
        },

        // Data Grid & Table
        mainGrid: {
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(12px)',
            borderRadius: '20px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
            overflow: 'hidden',
            position: 'relative',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            padding: '24px',
            width: '100%',
            margin: 'auto',
            '@media (max-width: 1024px)': {
                padding: '20px',
                maxWidth: '95%',
            },
            '@media (max-width: 768px)': {
                padding: '16px',
                maxWidth: '100%',
            }
        },
        dataGridContainer: {
            position: 'relative',
            width: '100%',
            overflowX: 'auto',
            minHeight: '400px',
            maxHeight: 'calc(100vh - 300px)',
            backgroundColor: 'white',
            borderRadius: '8px',
            '-webkit-overflow-scrolling': 'touch',
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
            },
            '&::-webkit-scrollbar-track': {
                background: 'rgba(0, 0, 0, 0.03)',
                borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'rgba(0, 0, 0, 0.15)',
                borderRadius: '4px',
            }
        },
        dataGridHeader: {
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 2,
            borderBottom: '2px solid rgba(0, 0, 0, 0.05)',
        },
        tableCell: {
            minWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            padding: '8px 16px',
        },

        // Status Badge
        statusBadge: {
            padding: '6px 8px',
            borderRadius: '12px',
            fontSize: '0.75rem',
            fontWeight: 500,
            display: 'inline-flex',
            alignItems: 'center',
            gap: '4px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&::before': {
                content: '""',
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: 'currentColor',
                opacity: 0.7
            }
        },

        // Utility Classes
        flex: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        },
        loadingContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            padding: '20px'
        }
    });


// Responsive column configuration
const getColumnSizes = () => {
    const isMobile = window.innerWidth <= 768;
    const isTablet = window.innerWidth <= 1024;

    return {
        name: {
            minWidth: isMobile ? 120 : 150,
            maxWidth: isMobile ? 160 : 250,
            width: isMobile ? 130 : 180
        },
        loanAmount: {
            minWidth: isMobile ? 100 : 120,
            maxWidth: isMobile ? 140 : 180,
            width: isMobile ? 110 : 140
        },
        loanType: {
            minWidth: isMobile ? 90 : 100,
            maxWidth: isMobile ? 120 : 150,
            width: isMobile ? 100 : 120
        },
        createdDate: {
            minWidth: isMobile ? 90 : 100,
            maxWidth: isMobile ? 120 : 150,
            width: isMobile ? 100 : 120
        },
        status: {
            minWidth: isMobile ? 100 : 120,
            maxWidth: isMobile ? 140 : 180,
            width: isMobile ? 110 : 140
        },
        lendingAgent: {
            minWidth: isMobile ? 120 : 150,
            maxWidth: isMobile ? 160 : 200,
            width: isMobile ? 130 : 170
        },
        lastUpdated: {
            minWidth: isMobile ? 120 : 150,
            maxWidth: isMobile ? 160 : 200,
            width: isMobile ? 130 : 170
        },
        icons: {
            minWidth: isMobile ? 80 : 100,
            maxWidth: isMobile ? 100 : 120,
            width: isMobile ? 90 : 110
        },
        menu: {
            minWidth: isMobile ? 40 : 50,
            maxWidth: isMobile ? 60 : 80,
            width: isMobile ? 50 : 60
        }
    };
};

const columnSizes = getColumnSizes();

// Define responsive column sizes
const getResponsiveColumnSizes = () => {
    const isMobile = window.innerWidth <= 768;
    const isTablet = window.innerWidth <= 1024;

    return {
        name: {
            minWidth: isMobile ? 120 : 180,
            width: isMobile ? 140 : 200,
            maxWidth: isMobile ? 160 : 250
        },
        loanAmount: {
            minWidth: isMobile ? 100 : 120,
            width: isMobile ? 120 : 150,
            maxWidth: isMobile ? 140 : 180
        },
        loanType: {
            minWidth: isMobile ? 90 : 100,
            width: isMobile ? 100 : 120,
            maxWidth: isMobile ? 120 : 150
        },
        createdDate: {
            minWidth: isMobile ? 90 : 100,
            width: isMobile ? 100 : 120,
            maxWidth: isMobile ? 120 : 150
        },
        status: {
            minWidth: isMobile ? 100 : 120,
            width: isMobile ? 120 : 140,
            maxWidth: isMobile ? 140 : 180
        },
        lendingAgent: {
            minWidth: isMobile ? 120 : 150,
            width: isMobile ? 140 : 180,
            maxWidth: isMobile ? 160 : 200
        },
        lastUpdated: {
            minWidth: isMobile ? 120 : 150,
            width: isMobile ? 140 : 180,
            maxWidth: isMobile ? 160 : 200
        },
        icons: {
            minWidth: 80,
            width: 100,
            maxWidth: 120
        },
        menu: {
            minWidth: 50,
            width: 60,
            maxWidth: 80
        }
    };
};


const StatCard = ({ title, value, icon: IconComponent, metrics, children }) => {
    const classes = useStyles();
    return (
        <div className={classes.statsCard}>
            <div className={classes.cardTitle}>
                {IconComponent && <IconComponent />}
                {title}
            </div>
            <div className={classes.mainValue}>{value}</div>
            <div className={classes.metricsContainer}>
                {metrics?.map((metric, index) => (
                    <div key={index} className={classes.metricItem}>
                        <span className={classes.metricValue}>{metric.value}</span>
                        <span className={classes.metricLabel}>{metric.label}</span>
                    </div>
                ))}
            </div>
            {children && (
                <div className={classes.shortcutCardWrapper}>
                    {children}
                </div>
            )}
        </div>
    );
};

const HomePage = () => {
    const classes = useStyles();
    const messageStore = useMessageStore();
    const tenantStore = useTenantStore();
    const navigate = useNavigate();
    const [sortColumn, setSortColumn] = useState('last-updated');
    const [sortDirection, setSortDirection] = useState('desc'); // 'asc' or 'desc'
    const [userData, setUserData] = useState(null);
    const [isStatusUpdating, setIsStatusUpdating] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [tab, setTab] = useState('');
    const [dialogState, setDialogState] = useState({
        create: false,
        addMember: false,
        assign: false,
        delete: false,
        resend: false,
        selectedApp: null,
        selectedContact: null
    });


    // Setup effect to watch tenantstore.user
    useEffect(() => {
        // Create an interval to check for user data
        const checkInterval = setInterval(() => {
            if (tenantStore.user) {
                setIsLoading(false);
                clearInterval(checkInterval);
            }
        }, 100); // Check every 100ms

        // Cleanup interval on component unmount
        return () => clearInterval(checkInterval);
    }, []); // Empty dependency array means this runs once on mount

    const { tenantId } = tenantStore.id; // Assuming tenantstore is accessible in this scope


    const fetchApplications = useCallback(async () => {
        if (!userData) return;

        try {

            const isAgent = tenantStore.tenantUsers?.find(
                user => user.email?.toLowerCase() === userData.email?.toLowerCase()
            )?.roles?.includes('Agent');

            console.log(isAgent)

            //const userDetails = {
            //    email: userData.email,
            //    email: userData.email,
            //    id: "MBR-1049ed4031006a8",
            //    mobilePhone: "0123456789",
            //    name: "aaron",
            //    roles: ['owner'],
            //    status: "Pending",
            //    title: null
            //};

            //useUserStore.getState().setUser(userDetails);

            const endpoint = isAgent ? '/api/applications' : '/api/applications/all';
            const data = await TenantApiClient.get(endpoint);
            setApplications(data);
        } catch (error) {
            messageStore.addAxiosError("Failed to return Applicants", error);
        } finally {
            setIsLoading(false);
        }
    }, [userData, tenantStore.tenantUsers]);

    // Single effect to handle initialization

    useEffect(() => {
        const initializeData = async () => {
            if (!tenantStore?.tenantUsers?.length) {
                console.log("Waiting for tenant users to load...");
                return;
            }

            try {
                console.log("Current tenantStore:", tenantStore);
                const userResponse = await TenantApiClient.get('/api/user');
                console.log("User response:", userResponse);
                const userEmail = userResponse?.email?.toLowerCase();

                if (!userEmail) {
                    console.error("User not authenticated");
                    return;
                }

                console.log("TenantStore users:", tenantStore?.tenantUsers);

                // Find the matching user in tenantUsers
                const currentUser = tenantStore.tenantUsers.find(user =>
                    user.email?.toLowerCase() === userEmail
                );

                if (!currentUser) {
                    console.error("User not found in tenant users");
                    return;
                }

                console.log("Current user roles:", currentUser.roles);

                // Check if user is an agent based on roles
                const isAgent = currentUser.roles.includes('Agent');
                console.log("Is user an agent?", isAgent);

                // Get applications based on role
                const endpoint = isAgent ? '/api/applications' : '/api/applications/all';
                console.log("Using endpoint:", endpoint);

                const applicationsData = await TenantApiClient.get(endpoint);
                setApplications(applicationsData);
                setIsLoading(false);

            } catch (error) {
                messageStore.addAxiosError("Failed to initialize", error);
                setIsLoading(false);
            }
        };

        initializeData();
    }, [messageStore, tenantStore, tenantStore?.tenantUsers]);

    const handleStatusUpdate = useCallback(async (application, newStatus) => {


        setIsStatusUpdating(true);

        const updateRequest = {
            name: application.name,
            description: application.description,
            loanConsiderationLow: application.loanConsiderationLow,
            loanConsiderationHigh: application.loanConsiderationHigh,
            status: newStatus
        };
        try {
            await TenantApiClient.put(`/api/applications/${application.id}`, updateRequest);

            messageStore.addMessage(`Status updated to ${newStatus} successfully!`);
            fetchApplications();
        } catch (error) {
            messageStore.addAxiosError("Failed to update status", error);
        } finally {
            setIsStatusUpdating(false);
        }
    }, [fetchApplications, messageStore]);



    useEffect(() => {
        fetchApplications();

    }, [fetchApplications]);

    const filteredApplications = useMemo(() =>
        tab ? applications.filter(a => a.status === tab) : applications,
        [applications, tab]
    );

    const sortedApplications = useMemo(() => {
        if (!sortColumn) return filteredApplications;
        return [...filteredApplications].sort((a, b) => {
            switch (sortColumn) {
                case 'last-updated':
                    const dateA = new Date(a.updateWhen);
                    const dateB = new Date(b.updateWhen);
                    return sortDirection === 'asc'
                        ? dateA - dateB
                        : dateB - dateA;
                // Add other cases for different columns if needed
                default:
                    return 0;
            }
        });
    }, [filteredApplications, sortColumn, sortDirection]);

    const handleAction = useCallback((action, application, e) => {
        e?.stopPropagation();

        switch (action) {
            case 'edit':
                navigate(`/applicant/${application.id}`);
                break;
            case 'delete':
            case 'assign':
            case 'resend':
                setDialogState(prev => ({
                    ...prev,
                    [action]: true,
                    selectedApp: application,
                    selectedContact: action === 'resend' ? application.contacts?.[0] : null
                }));
                break;
            default:
                break;
        }
    }, [navigate]);

    const handleDialogClose = useCallback((dialogType, shouldRefresh = false) => {
        setDialogState(prev => ({
            ...prev,
            [dialogType]: false,
            selectedApp: null,
            selectedContact: null
        }));

        if (shouldRefresh) {
            fetchApplications();
        }
    }, [fetchApplications]);



    // Responsive column configuration
    const getColumnSizes = () => {
        const isMobile = window.innerWidth <= 768;
        const isTablet = window.innerWidth <= 1024;

        return {
            name: {
                minWidth: isMobile ? 120 : 150,
                maxWidth: isMobile ? 160 : 250,
                width: isMobile ? 130 : 180
            },
            loanAmount: {
                minWidth: isMobile ? 100 : 120,
                maxWidth: isMobile ? 140 : 180,
                width: isMobile ? 110 : 140
            },
            loanType: {
                minWidth: isMobile ? 90 : 100,
                maxWidth: isMobile ? 120 : 150,
                width: isMobile ? 100 : 120
            },
            createdDate: {
                minWidth: isMobile ? 90 : 100,
                maxWidth: isMobile ? 120 : 150,
                width: isMobile ? 100 : 120
            },
            status: {
                minWidth: isMobile ? 100 : 120,
                maxWidth: isMobile ? 140 : 180,
                width: isMobile ? 110 : 140
            },
            lendingAgent: {
                minWidth: isMobile ? 120 : 150,
                maxWidth: isMobile ? 160 : 200,
                width: isMobile ? 130 : 170
            },
            lastUpdated: {
                minWidth: isMobile ? 120 : 150,
                maxWidth: isMobile ? 160 : 200,
                width: isMobile ? 130 : 170
            },
            icons: {
                minWidth: isMobile ? 80 : 100,
                maxWidth: isMobile ? 100 : 120,
                width: isMobile ? 90 : 110
            },
            menu: {
                minWidth: isMobile ? 40 : 50,
                maxWidth: isMobile ? 60 : 80,
                width: isMobile ? 50 : 60
            }
        };
    };

    const columnSizes = getColumnSizes();

    // Define responsive column sizes
    const getResponsiveColumnSizes = () => {
        const isMobile = window.innerWidth <= 768;
        const isTablet = window.innerWidth <= 1024;

        return {
            name: {
                minWidth: isMobile ? 120 : 180,
                width: isMobile ? 140 : 200,
                maxWidth: isMobile ? 160 : 250
            },
            loanAmount: {
                minWidth: isMobile ? 100 : 120,
                width: isMobile ? 120 : 150,
                maxWidth: isMobile ? 140 : 180
            },
            loanType: {
                minWidth: isMobile ? 90 : 100,
                width: isMobile ? 100 : 120,
                maxWidth: isMobile ? 120 : 150
            },
            createdDate: {
                minWidth: isMobile ? 90 : 100,
                width: isMobile ? 100 : 120,
                maxWidth: isMobile ? 120 : 150
            },
            status: {
                minWidth: isMobile ? 100 : 120,
                width: isMobile ? 120 : 140,
                maxWidth: isMobile ? 140 : 180
            },
            lendingAgent: {
                minWidth: isMobile ? 120 : 150,
                width: isMobile ? 140 : 180,
                maxWidth: isMobile ? 160 : 200
            },
            lastUpdated: {
                minWidth: isMobile ? 120 : 150,
                width: isMobile ? 140 : 180,
                maxWidth: isMobile ? 160 : 200
            },
            icons: {
                minWidth: 80,
                width: 100,
                maxWidth: 120
            },
            menu: {
                minWidth: 50,
                width: 60,
                maxWidth: 80
            }
        };
    };

    const columns = useMemo(() => [
        createTableColumn({
            columnId: 'name',
            renderHeaderCell: () => 'Name',
            renderCell: (application) => (
                <TableCellLayout className={classes.tableCell} style={{
                    minWidth: columnSizes.name.minWidth,
                    maxWidth: columnSizes.name.maxWidth
                }}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {application.primaryContact.firstName} {application.primaryContact.lastName}
                    </div>
                </TableCellLayout>
            ),
            compare: (a, b) => {
                const nameA = `${a.primaryContact.firstName} ${a.primaryContact.lastName}`.toLowerCase();
                const nameB = `${b.primaryContact.firstName} ${b.primaryContact.lastName}`.toLowerCase();
                return nameA.localeCompare(nameB);
            }
        }),

        createTableColumn({
            columnId: 'LoanAmount',
            renderHeaderCell: () => 'Loan Amount',
            renderCell: (application) => (
                <TableCellLayout className={classes.tableCell} style={{
                    minWidth: columnSizes.name.minWidth,
                    maxWidth: columnSizes.name.maxWidth
                }}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        ${application.loanConsiderationLow}-${application.loanConsiderationHigh}
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: 'LoanType',
            renderHeaderCell: () => 'Loan Type',
            renderCell: (application) => (
                <TableCellLayout className={classes.tableCell} style={{
                    minWidth: columnSizes.name.minWidth,
                    maxWidth: columnSizes.name.maxWidth
                }}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {application.applicationType}
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: 'created-date',
            renderHeaderCell: () => 'Created On',
            renderCell: (application) => (
                <TableCellLayout className={classes.tableCell} style={{
                    minWidth: columnSizes.name.minWidth,
                    maxWidth: columnSizes.name.maxWidth
                }}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {new Date(application.createdDate).toLocaleDateString()}
                    </div>
                </TableCellLayout>
            ),
            sortable: true,
            compare: (a, b) => {
                const dateA = new Date(a.updateWhen);
                const dateB = new Date(b.updateWhen);
                return dateA - dateB;
            },
        }),
        // Update the status column in the columns array
  
        createTableColumn({
            columnId: 'status',
            renderHeaderCell: () => 'Status',
            renderCell: (application) => {
                // Convert status to display text
                const displayStatus = application.status === 'WaitingForApplicant'
                    ? 'Waiting'
                    : application.status;

                return (
                    <TableCellLayout className={classes.tableCell} style={{
                        minWidth: columnSizes.status.minWidth,
                        maxWidth: columnSizes.status.maxWidth,
                        width: columnSizes.status.width
                    }}>

                            <div
                                className={classes.statusBadge}
                                style={{
                                    backgroundColor: getStatusColor(application.status).bg,
                                    color: getStatusColor(application.status).text,
                                }}
                            >
                                {displayStatus}
                            </div>
               
                    </TableCellLayout>
                );
            },
            compare: (a, b) => a.status.localeCompare(b.status),
        }),
           createTableColumn({
               columnId: 'lending-agent',
               renderHeaderCell: () => 'Lending Agent',
               renderCell: (application) => (
                   <TableCellLayout className={classes.tableCell} style={{
                       minWidth: columnSizes.name.minWidth,
                       maxWidth: columnSizes.name.maxWidth
                   }}>
                       <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                           {application.createdWho}
                       </div>
                   </TableCellLayout>
               ),
               compare: (a, b) => a.createdWho.localeCompare(b.createdWho)
           }),

        createTableColumn({
            columnId: 'last-updated',
            renderHeaderCell: () => 'Last Updated',
            renderCell: (application) => {
                const date = new Date(application.updateWhen);
                // Format date to be more compact
                const formattedDate = new Intl.DateTimeFormat('en-US', {
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                }).format(date);

                return (
                    <TableCellLayout
                        className={classes.tableCell}
                        style={{
                            minWidth: columnSizes.lastUpdated.minWidth,
                            maxWidth: columnSizes.lastUpdated.maxWidth,
                            width: columnSizes.lastUpdated.width
                        }}
                    >
                        <Tooltip
                            content={date.toLocaleString()}
                            relationship="label"
                        >
                            <div className={classes.tableCellContent}>
                                {formattedDate}
                            </div>
                        </Tooltip>
                    </TableCellLayout>
                );
            },
            sortable: true,
            compare: (a, b) => {
                const dateA = new Date(a.updateWhen);
                const dateB = new Date(b.updateWhen);
                return dateA - dateB;
            },
        }),
        createTableColumn({
            columnId: 'icons',
            renderHeaderCell: () => null,
            renderCell: (application) => (
                <TableCellLayout
                    className={classes.tableCell}
                    style={{
                        minWidth: columnSizes.icons.minWidth,
                        maxWidth: columnSizes.icons.maxWidth,
                        width: columnSizes.icons.width,
                        padding: '4px'
                    }}
                >
                    <div className={mergeClasses(classes.flex, classes.iconContainer)}>
                        {application.primaryContact.plaidLink && (
                            <Tooltip content="Plaid Linked" relationship="label">
                                <Avatar
                                    name="Plaid"
                                    image={{ src: plaidLogo }}
                                    size={24}
                                    className={classes.iconAvatar}
                                />
                            </Tooltip>
                        )}
                        {application.primaryContact.finicityLink && (
                            <Tooltip content="Finicity Linked" relationship="label">
                                <Avatar
                                    name="Finicity"
                                    image={{ src: finicityLogo }}
                                    size={24}
                                    className={classes.iconAvatar}
                                />
                            </Tooltip>
                        )}
                    </div>
                </TableCellLayout>
            ),
        }),

        createTableColumn({
            columnId: 'menu',
            renderHeaderCell: () => null,
            renderCell: (item) => (
                <TableCellLayout className={classes.colRight}>
                    <div className={mergeClasses(classes.flex, classes.footer)}>
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <Button
                                    icon={<MoreVerticalRegular />}
                                    onClick={e => e.stopPropagation()}
                                    disabled={isStatusUpdating}
                                />
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    <MenuItem icon={<EditRegular />} onClick={e => handleAction('edit', item, e)}>
                                        Edit
                                    </MenuItem>
                                    <MenuItem icon={<DeleteRegular />} onClick={e => handleAction('delete', item, e)}>
                                        Delete
                                    </MenuItem>
                                    <MenuItem icon={<PeopleCheckmarkRegular />} onClick={e => handleAction('assign', item, e)}>
                                        Assign
                                    </MenuItem>
                                    <MenuItem icon={<SendRegular />} onClick={e => handleAction('resend', item, e)}>
                                        Resend Application
                                    </MenuItem>
                                    <MenuList>
                                        <MenuItem
                                            icon={<ArrowSyncRegular />}
                                            disabled={isStatusUpdating}
                                        >
                                            <div style={{ width: '100%' }}>
                                                <Dropdown
                                                    className={classes.statusDropdown}
                                                    value={item.status}
                                                    onOptionSelect={(_, data) => {
                                                        if (data.optionValue !== item.status) {
                                                            handleStatusUpdate(item, data.optionValue);
                                                        }
                                                    }}
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    {STATUS_OPTIONS.map(status => (
                                                        <Option
                                                            key={status.value}
                                                            value={status.value}
                                                            className={classes.statusOption}
                                                        >
                                                            <div
                                                                className={classes.statusBadge}
                                                                style={{
                                                                    backgroundColor: getStatusColor(status.value).bg,
                                                                    color: getStatusColor(status.value).text
                                                                }}
                                                            >
                                                                {status.label}
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </Dropdown>
                                            </div>
                                        </MenuItem>
                                    </MenuList>
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div>
                </TableCellLayout>
            ),
        }),

    ], [classes, handleAction, handleStatusUpdate, isStatusUpdating]);

    const countByStatus = useMemo(() => {
        return applications.reduce((acc, app) => {
            acc[app.status] = (acc[app.status] || 0) + 1;
            return acc;
        }, {});
    }, [applications]);

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                titleColor: tokens.colorNeutralForeground1,
                bodyColor: tokens.colorNeutralForeground1,
                borderColor: tokens.colorNeutralStroke1,
                borderWidth: 1,
                padding: 12,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: 8
            }
        }
    };
    const chartData = {
        labels: Object.keys(countByStatus).map(status => STATUS_OPTIONS.find(opt => opt.value === status)?.label || status),
        datasets: [{
            data: Object.values(countByStatus),
            backgroundColor: Object.keys(countByStatus).map(status => getStatusColor(status).bg),
            borderColor: Object.keys(countByStatus).map(status => getStatusColor(status).text),
            borderWidth: 1
        }]
    };


    return (
        <div className={classes.root}>
        
            <CreateApplicationDialog
                showDialog={dialogState.create}
                onCancelDialog={() => handleDialogClose('create')}
                onComplete={applicationId => {
                    handleDialogClose('create');
                    navigate('/Applicant/' + applicationId);
                }}
            />
            <AddMemberDialog
                tenantId={tenantStore.id}
                showDialog={dialogState.addMember}
                onCancel={() => handleDialogClose('addMember')}
                onComplete={() => handleDialogClose('addMember')}
            />
            <AssignApplicationDialog
                showDialog={dialogState.assign}
                onCancel={() => handleDialogClose('assign')}
                application={dialogState.selectedApp}
                onComplete={() => handleDialogClose('assign', true)}
            />
            <DeleteApplicationDialog
                showDialog={dialogState.delete}
                onCancel={() => handleDialogClose('delete')}
                application={dialogState.selectedApp}
                onComplete={success => handleDialogClose('delete', success)}
            />
            <RequestFollowUpDialog
                showDialog={dialogState.resend}
                onCancel={() => handleDialogClose('resend')}
                application={dialogState.selectedApp}
                contacts={dialogState.selectedContact}
                onRequestSent={() => handleDialogClose('resend')}
            />
        
                <div className={classes.statsContainer}>
                <StatCard
                    title="Applications"
                    value={applications.length}
                    icon={DocumentPdfRegular}
                    metrics={[

                    ]}
                >
                    <ShortcutCard
                        title="Create New Invitation"
                        onClick={() => setDialogState(prev => ({ ...prev, create: true }))}
                        icon={<SendFilled />}
                    />
                </StatCard>

                <StatCard
                    title="Users"
                    value={tenantStore.tenantUsers.length}
                    icon={PersonRegular}

                >
                    <ShortcutCard
                        title="Create New Agent"
                        onClick={() => setDialogState(prev => ({ ...prev, addMember: true }))}
                        icon={<PeopleCheckmarkRegular />}
                    />
                </StatCard>

                <div className={classes.statsCard}>
                    <div className={classes.cardTitle}>
                        Status Distribution
                    </div>
                    <div className={classes.pieChartContainer}>
                        <Pie
                            data={chartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: { display: false }
                                }
                            }}
                        />
                    </div>
                </div>

                <div className={classes.statsCard}>
                    <div className={classes.cardTitle}>
                        <Alert28Regular />
                        Notifications
                    </div>
                    <div className={classes.notificationsWrapper}>
                        {isLoading ? (
                            <div className={classes.loadingContainer}>
                                <Spinner size="small" />
                                <span>Loading activity log...</span>
                            </div>
                        ) : (
                            <ActivityLog />
                        )}
                    </div>
                </div>
            </div>
                   

                    <Card className={classes.mainGrid}>
                        <TabList
                            className={classes.tabList}
                            selectedValue={tab}
                            onTabSelect={(_, data) => setTab(data.value)}
                        >
                            <Tab value="">All</Tab>
                            <Tab value="Created">New</Tab>
                            <Tab value="WaitingForApplicant">Waiting</Tab>
                            <Tab value="Ready">Ready</Tab>
                            <Tab value="Approved">Approved</Tab>
                            <Tab value="Rejected">Rejected</Tab>
                        </TabList>

                            <div className={classes.dataGridContainer}>
                                <DataGrid
                                    items={sortedApplications}
                                    columns={columns}
                                    getRowId={(item) => item.id}
                                    sortable={true}
                                    sort={{
                                        columnId: sortColumn,
                                        direction: sortDirection,
                                    }}
                                    onSortChange={(e, data) => {
                                        setSortColumn(data.columnId);
                                        setSortDirection(data.direction);
                                    }}
                                    style={{
                                        width: '100%',
                                        tableLayout: 'fixed',
                                        borderCollapse: 'separate',
                                        borderSpacing: 0
                                    }}

                                >
                                    <DataGridHeader className={classes.dataGridHeader}>
                                        <DataGridRow>
                                            {({ renderHeaderCell }) => (
                                                <DataGridHeaderCell
                                                    className={classes.tableCell}
                                                >
                                                    {renderHeaderCell()}
                                                </DataGridHeaderCell>
                                            )}
                                        </DataGridRow>
                                    </DataGridHeader>
                                    <DataGridBody>
                                        {({ item, rowId }) => (
                                            <DataGridRow
                                                key={rowId}
                                                onClick={(e) => handleAction('edit', item, e)}
                                                className={classes.row}
                                            >
                                                {({ renderCell }) => (
                                                    <DataGridCell className={classes.tableCell}>
                                                        {renderCell(item)}
                                                    </DataGridCell>
                                                )}
                                            </DataGridRow>
                                        )}
                                    </DataGridBody>
                        </DataGrid>
                    </div>
                </Card>
            </div>
       
    );
}
export default HomePage;