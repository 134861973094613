import React, { useState, useEffect, useCallback } from 'react';
import useUserStore from 'stores/userStore';
import useMessageStore from 'stores/messageStore';
import { TenantApiClient } from 'providers/TenantApiClient';
import {
    PersonRegular,
    Mail24Regular,
    Phone24Regular,
    ShieldLockRegular
} from "@fluentui/react-icons";

const Profile = () => {
    const { getUser, setUser, firstName, lastName, email, mobilePhone, roles, loading: userLoading, error: userError } = useUserStore();
    const messageStore = useMessageStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Initialize profile data state
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
        profileImage: null,
        roles: null
    });

    // Load user data when component mounts
    useEffect(() => {
        const loadUserData = async () => {
            try {
                setLoading(true);
                const response = await TenantApiClient.get('/api/user');
                console.log('API Response:', response);

                // Explicitly set the user data in the store
                setUser({
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.email,
                    mobilePhone: response.mobilePhone,
                    roles: response.roles
                });

                // Also update local state
                setProfileData(prev => ({
                    ...prev,
                    firstName: response.firstName || '',
                    lastName: response.lastName || '',
                    email: response.email || '',
                    mobilePhone: response.mobilePhone || '',
                    roles: response.roles
                }));
            } catch (err) {
                console.error('Error in loadUserData:', err);
                setError('Failed to load user data');
                messageStore.addMessage('Error', 'Failed to load user data', 'error');
            } finally {
                setLoading(false);
            }
        };

        loadUserData();
    }, []);

    // Update form when user data changes
    useEffect(() => {
        if (firstName || lastName || email || mobilePhone|| roles) {
            console.log('User data changed:', { firstName, lastName, email, mobilePhone });
            setProfileData(prev => ({
                ...prev,
                firstName: firstName || prev.firstName,
                lastName: lastName || prev.lastName,
                email: email || prev.email,
                mobilePhone: mobilePhone || prev.mobilePhone,
                roles: roles || prev.roles
            }));
        }
    }, [firstName, lastName, email, mobilePhone, roles]);

    const handleImageUpload = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileData((prev) => ({
                    ...prev,
                    profileImage: e.target.result
                }));
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const handleInputChange = useCallback((field, value) => {
        setProfileData((prev) => ({
            ...prev,
            [field]: value
        }));
    }, []);

    const updateUser = async (updatedUserData) => {
        try {
            const response = await TenantApiClient.put(
                '/api/user',
                updatedUserData
            );
            return response;
        } catch (error) {
            throw error;
        }
    };

    const handleSubmit = useCallback(async () => {
        try {
            setLoading(true);
            const updatedUserData = {
                firstName: profileData.firstName,
                lastName: profileData.lastName,
                email: profileData.email,
                mobilePhone: profileData.mobilePhone
            };

            await updateUser(updatedUserData);
            setUser(updatedUserData);
            messageStore.addMessage('Success', 'Profile has been updated', 'success');

        } catch (error) {
            messageStore.addAxiosError('Failed to update profile', error);
        } finally {
            setLoading(false);
        }
    }, [profileData, setUser, messageStore]);

    if (loading || userLoading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error || userError) {
        return <div className="text-red-500 text-center">{error || userError}</div>;
    }

    return (
        <div className="max-w-6xl mx-auto p-3">
            <h1 className="text-3xl font-semibold mb-6 text-center text-blue-600">Personal Profile Information</h1>

            <div className="flex flex-col items-center mb-8 relative">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                    id="imageUpload"
                />
                <label htmlFor="imageUpload" className="cursor-pointer">
                    {profileData.profileImage ? (
                        <img src={profileData.profileImage} alt="Profile" className="w-32 h-32 rounded-full object-cover" />
                    ) : (
                        <div className="w-32 h-32 bg-gray-200 flex items-center justify-center rounded-full">
                            <span className="text-gray-500">Upload Image</span>
                        </div>
                    )}
                </label>
            </div>

            <div className="flex">
                {/* Left Column - Personal Info */}
                <div className="flex-1 pr-8 border-r border-gray-200">
                    <h2 className="text-xl font-semibold mb-6 text-gray-700">Personal Information</h2>
                    <div className="space-y-6">
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <PersonRegular className="text-gray-400" />
                                </div>
                                <input
                                    value={profileData.firstName}
                                    onChange={e => handleInputChange('firstName', e.target.value)}
                                    placeholder="First Name"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <PersonRegular className="text-gray-400" />
                                </div>
                                <input
                                    value={profileData.lastName}
                                    onChange={e => handleInputChange('lastName', e.target.value)}
                                    placeholder="Last Name"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Mail24Regular className="text-gray-400" />
                                </div>
                                <input
                                    value={profileData.email}
                                    onChange={e => handleInputChange('email', e.target.value)}
                                    placeholder="Email"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Mobile Phone</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Phone24Regular className="text-gray-400" />
                                </div>
                                <input
                                    value={profileData.mobilePhone}
                                    onChange={e => handleInputChange('mobilePhone', e.target.value)}
                                    placeholder="Mobile Phone"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Column - Roles */}
                <div className="flex-1 pl-8">
                    <div className="flex items-center gap-2 mb-6">
                        <ShieldLockRegular className="text-blue-600" />
                        <h2 className="text-xl font-semibold text-gray-700">Roles</h2>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {roles?.map((role, index) => (
                            <span
                                key={index}
                                className="px-4 py-2 bg-blue-100 text-blue-800 rounded-full text-sm font-medium capitalize"
                            >
                                {role}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            <div className="mt-8 pt-6 border-t border-gray-200">
                <button
                    className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? 'Updating...' : 'Update Profile'}
                </button>
            </div>
        </div>
    );
};

export default Profile;