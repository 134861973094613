import React, {useState, useEffect} from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import Slider from '@mui/material/Slider';

import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Input,
    Field,
    Checkbox,
    Button,
    ProgressBar,
    Text,
    Divider,
  } from "@fluentui/react-components";
import {
    SaveFilled,
} from "@fluentui/react-icons";

const useAddClasses = makeStyles({
    dialogSurface: {
        borderRadius: '16px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.12)',
        maxWidth: '600px',
        width: '90%'
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
        padding: '24px'
    },
    dialogTitle: {
        fontSize: '1.5rem',
        fontWeight: '600',
        color: tokens.colorNeutralForeground1,
        padding: '24px 24px 0'
    },
    dialogActions: {
        padding: '16px 24px 24px',
        display: 'flex',
        gap: '12px',
        justifyContent: 'flex-end'
    },
    field: {
        '& label': {
            fontSize: '0.875rem',
            fontWeight: '500',
            color: tokens.colorNeutralForeground1,
            marginBottom: '6px'
        }
    },
    input: {
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        '&:hover': {
            borderColor: tokens.colorBrandBackground,
        },
        '&:focus': {
            borderColor: tokens.colorBrandBackground,
            boxShadow: `0 0 0 2px ${tokens.colorBrandBackground}20`
        }
    },
    slider: {
        width: "90%",
        margin: "16px auto",
        '& .MuiSlider-rail': {
            backgroundColor: tokens.colorNeutralBackground4
        },
        '& .MuiSlider-track': {
            backgroundColor: tokens.colorBrandBackground
        },
        '& .MuiSlider-thumb': {
            backgroundColor: '#ffffff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            border: `2px solid ${tokens.colorBrandBackground}`,
            '&:hover, &.Mui-active': {
                boxShadow: '0 0 0 8px rgba(0, 120, 212, 0.16)'
            }
        },
        '& .MuiSlider-valueLabel': {
            backgroundColor: tokens.colorBrandBackground,
            borderRadius: '6px',
            padding: '4px 8px',
            fontSize: '0.75rem'
        }
    },
    progressContainer: {
        marginTop: '16px'
    },
    progressBar: {
        height: '4px',
        borderRadius: '2px'
    },
    progressText: {
        fontSize: '0.875rem',
        color: tokens.colorNeutralForeground2,
        marginBottom: '8px'
    },
    divider: {
        margin: '16px 0',
        backgroundColor: tokens.colorNeutralBackground4
    },
    saveButton: {
        backgroundColor: tokens.colorBrandBackground,
        color: '#ffffff',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: tokens.colorBrandBackgroundHover,
            transform: 'translateY(-1px)'
        }
    },
    cancelButton: {
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2
        }
    }
});

const EditApplicationDialog = ({application, showDialog, onCancel, onComplete}) =>{
    const classes = useAddClasses();
    const messageStore = useMessageStore();
    const [progressMessage, setProgressMessage] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [loanRange, setLoanRange] = React.useState([application.loanConsiderationLow, application.loanConsiderationHigh]);

    const handleLoanChange = (event, newValue) => {
        setLoanRange(newValue);
    };

    function valueLabelFormat(num) {
        const formattedNumber = Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
        return `$${formattedNumber}`;
    }

    const handleAddApplication = (ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        const updateRequest = {
            firstName: formProps.firstName,
            lastName: formProps.lastName,
            description: formProps.description,
            loanConsiderationLow: loanRange[0],
            loanConsiderationHigh: loanRange[1],
        };

        setIsBusy(true);
        setProgressMessage("Progress: Updating Application");

        TenantApiClient.put(`/api/applications/${application.id}`, updateRequest)
            .then((data) => {
                setIsBusy(false);
                if (onComplete) {
                    onComplete(data.id);
                    window.location.reload(); // Force reload after successful update
                }
            })
            .catch((error) => {
                setIsBusy(false);
                console.error(error);
                messageStore.addAxiosError("Failed to update application", error);
                onCancel();
            });
    };

    return (
        <Dialog
            open={showDialog}
            onOpenChange={(event, data) => onCancel(data.open)}
        >
            <DialogSurface className={classes.dialogSurface}>
                <form onSubmit={handleAddApplication}>
                    <DialogBody>
                        <DialogTitle className={classes.dialogTitle}>
                            Update Loan Contact Details
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Field
                                required
                                label="Applicant First Name"
                                className={classes.field}
                            >
                                <Input
                                    required
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    defaultValue={application.contacts[0].firstName}
                                    className={classes.input}
                                />

                            </Field>
                            <Field
                                required
                                label="Applicant Last Name"
                                className={classes.field}
                            >
                                <Input
                                    required
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    defaultValue={application.contacts[0].lastName}
                                    className={classes.input}
                                />

                            </Field>
                            <Field
                                required
                                label="Applicant Email"
                                className={classes.field}
                            >
                                <Input
                                    required
                                    type="text"
                                    name="email"
                                    id="email"
                                    defaultValue={application.contacts[0].email}
                                    className={classes.input}
                                />
                            </Field>
                            <Field
                                required
                                label="Applicant Phone"
                                className={classes.field}
                            >
                                <Input
                                    required
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    defaultValue={application.contacts[0].mobilePhone}
                                    className={classes.input}
                                />
                            </Field>
                            <Field
                                required
                                label="Description"
                                className={classes.field}
                            >
                                <Input
                                    required
                                    type="text"
                                    name="description"
                                    id="description"
                                    defaultValue={application.description}
                                    className={classes.input}
                                />
                            </Field>
                            <Field
                                required
                                label={`Loan Range (${valueLabelFormat(loanRange[0])} to ${valueLabelFormat(loanRange[1])})`}
                                className={classes.field}
                            >
                                <Slider
                                    className={classes.slider}
                                    getAriaLabel={() => 'Loan Range'}
                                    value={loanRange}
                                    onChange={handleLoanChange}
                                    valueLabelFormat={valueLabelFormat}
                                    max={500000}
                                    min={1000}
                                    step={1000}
                                    valueLabelDisplay="auto"
                                />
                            </Field>

                            <Divider className={classes.divider} />

                            {isBusy && (
                                <div className={classes.progressContainer}>
                                    <Text className={classes.progressText}>
                                        {progressMessage}
                                    </Text>
                                    <ProgressBar className={classes.progressBar} />
                                </div>
                            )}
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    appearance="secondary"
                                    className={classes.cancelButton}
                                >
                                    Cancel
                                </Button>
                            </DialogTrigger>
                            <Button
                                type="submit"
                                icon={<SaveFilled />}
                                appearance="primary"
                                className={classes.saveButton}
                            >
                                Save Changes
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};


export default EditApplicationDialog;