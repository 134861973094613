import React, { useState, useEffect } from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Combobox,
    Option,
    Persona,
    Button,
    ProgressBar,
    Text,
    Divider,
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Spinner,
    Caption1,
} from "@fluentui/react-components";

import {
    Dismiss24Regular,
    CheckmarkCircle24Regular,
    CalendarArrowDownFilled
} from "@fluentui/react-icons";

const useFetchStatementsClasses = makeStyles({
    dialog: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
    dialogButton: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
    accountComboBox: {
    },
    spinnerContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
    },
    statementCard: {
        maxHeight: "400px",
        overflow: "auto",
        position: "relative", // Add this for spinner positioning
    },
    statementTable: {
        minHeight: "300px",
    },
    statementTableBody: {
        maxHeight: "400px",
        height: "100%",
    },
    statementTableFiller: {
        height: "100%",
    },
});

const FetchStatementsDialog = ({ application, accounts = [], showDialog, onClose }) => {
    const classes = useFetchStatementsClasses();
    const messageStore = useMessageStore();
    const [isBusy, setIsBusy] = useState(false);
    const [duration, setDuration] = useState(0);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [statements, setStatements] = useState([]);
    const [isDownloadEnabled, setDownloadEnabled] = useState(false);
    const filteredAccounts = accounts.filter(account =>
        account.source === "Finicity" || account.source === "Plaid"
    );
    const handleOpenChange = () => {
        setDuration(0);
        setSelectedAccount(null);
        setStatements([]);
        setDownloadEnabled(false);
        if (onClose) {
            onClose();
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const handleAccountSelection = (event, item) => {
        if (!item || !accounts) return;

        const account = accounts.find(account => account.accountId === item.optionValue);
        if (account) {
            setSelectedAccount(account);
            if (duration > 0) {
                fetchStatements(account, duration);
            }
        }
    };

    const handleDurationSelection = (event, item) => {
        if (!item) return;

        const durationValue = parseInt(item.optionValue);
        setDuration(durationValue);

        if (selectedAccount) {
            fetchStatements(selectedAccount, durationValue);
        }
    };

    const applyStatements = (results = []) => {
        const availableStatements = results.filter(item => item?.status === "Available");
        setDownloadEnabled(availableStatements.length > 0);
        setStatements(results);
    };

    const fetchStatements = async (account, count) => {
        if (!account?.accountId || !count) return;

        messageStore.clearMessages();
        setIsBusy(true);

        try {
            let response;
            if (account.source === "Finicity") {
                response = await TenantApiClient.get(
                    `/api/finicity/statements/${application.id}/${application.primaryContact.contactId}/${account.accountId}/${count}`
                );
            } else if (account.source === "Plaid") {
                response = await TenantApiClient.get(
                    `/api/plaid/statements/${application.id}/${application.primaryContact.contactId}/${account.accountId}/${count}`
                );
            }

            if (response) {
                const formattedStatements = response.map(statement => ({
                    id: statement.id,
                    description: statement.description,
                    status: statement.status,
                    asOfDate: statement.asOfDate
                }));
                applyStatements(formattedStatements);
            }
        } catch (error) {
            
            messageStore.addAxiosError(`Error fetching ${account.source} statements`, error);
            setIsBusy(false);
            handleOpenChange();
        } finally {
            setIsBusy(false);
        }
    };

    const importStatement = async (id) => {
        if (!selectedAccount?.accountId || !id) return;

        try {
            const endpoint = selectedAccount.source === "Finicity"
                ? `/api/finicity/import/${application.id}/${application.primaryContact.contactId}/${selectedAccount.accountId}/${id}`
                : `/api/plaid/import/${application.id}/${application.primaryContact.contactId}/${selectedAccount.accountId}/${id}`;

            await TenantApiClient.post(endpoint);

            setStatements(prevStatements =>
                prevStatements.map(statement =>
                    statement.id === id
                        ? { ...statement, status: "Downloaded" }
                        : statement
                )
            );
        } catch (error) {
            messageStore.addAxiosError("Failed to import statement", error);
        }
    };
    const importStatements = async () => {
        const statementsToImport = statements.filter(s => s.status === "Available");
        setStatements(prevStatements =>
            prevStatements.map(statement =>
                statement.status === "Available"
                    ? { ...statement, status: "Importing" }
                    : statement
            )
        );

        for (const statement of statementsToImport) {
            try {
                const endpoint = selectedAccount.source === "Finicity"
                    ? `/api/finicity/import/${application.id}/${application.primaryContact.contactId}/${selectedAccount.accountId}/${statement.id}`
                    : `/api/plaid/import/${application.id}/${application.primaryContact.contactId}/${selectedAccount.accountId}/${statement.id}`;

                await TenantApiClient.post(endpoint);

                setStatements(prevStatements =>
                    prevStatements.map(s =>
                        s.id === statement.id
                            ? { ...s, status: "Downloaded" }
                            : s
                    )
                );
            } catch (error) {
               
                messageStore.addAxiosError("Failed to import statement", error);
                handleOpenChange();
            }
        }

        setDownloadEnabled(false);
    };

    const columns = [
        { columnKey: "description", label: "Description" },
        { columnKey: "asOfDate", label: "Date As Of" },
        { columnKey: "status", label: "Status" },
    ];

    if (!application || !application.primaryContact) {
        return null;
    }

    return (
        <Dialog open={showDialog} onOpenChange={handleOpenChange}>
            <DialogSurface className={classes.dialog}>
                <DialogBody>
                    <DialogTitle action={
                        <DialogTrigger action="close">
                            <Button
                                appearance="subtle"
                                aria-label="close"
                                icon={<Dismiss24Regular />}
                            />
                        </DialogTrigger>}>
                        Fetch Statements
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Label htmlFor="account-input">Account</Label>
                        <Combobox
                            id="account-input"
                            className={classes.accountComboBox}
                            placeholder="Select Account"
                            onOptionSelect={handleAccountSelection}
                        >
                          
                            {filteredAccounts.map((account) => (
                                <Option
                                    key={account.accountId}
                                    value={account.accountId}
                                    text={`${account.institutionName || ''} - ${account.accountName || ''} (${account.accountNumberDisplay || ''})`}
                                >
                                    <Persona
                                        name={`${account.institutionName || ''} - ${account.accountName || ''} (${account.accountNumberDisplay || ''})`}
                                    />
                                </Option>
                            ))}
                        </Combobox>

                        <Label htmlFor="count-input">Time Span</Label>
                        <Combobox
                            id="count-input"
                            placeholder="Select Duration"
                            onOptionSelect={handleDurationSelection}
                        >
                            <Option value="1">1 Month</Option>
                            <Option value="2">2 Months</Option>
                            <Option value="3">3 Months</Option>
                            <Option value="4">4 Months</Option>
                            <Option value="5">5 Months</Option>
                            <Option value="6">6 Months</Option>
                            <Option value="12">1 Year</Option>
                            <Option value="24">2 Years</Option>
                        </Combobox>

                        <Label>Statements</Label>
                        <Card className={classes.statementCard}>
                            <Table size="small" className={classes.statementTable}>
                                <TableHeader>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableHeaderCell key={column.columnKey}>
                                                {column.label}
                                            </TableHeaderCell>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody className={classes.statementTableBody}>
                                    {isBusy ? (
                                        <div className={classes.spinnerContainer}>
                                            <Spinner />
                                        </div>
                                    ) : (
                                        statements.map((statement) => (
                                            <TableRow key={statement.id}>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {statement.description}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {formatDate(statement.asOfDate)}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {statement.status === "Importing" && (
                                                            <Caption1>
                                                                <Spinner />
                                                            </Caption1>
                                                        )}
                                                        {statement.status === "Downloaded" && (
                                                            <Caption1>
                                                                <CheckmarkCircle24Regular />
                                                            </Caption1>
                                                        )}
                                                        {(statement.status === "Available" || statement.status === "Unavailable") && (
                                                            statement.status
                                                        )}
                                                    </TableCellLayout>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                    <TableRow className={classes.statementTableFiller}></TableRow>
                                </TableBody>
                            </Table>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={!isDownloadEnabled}
                            onClick={importStatements}
                            appearance="primary"
                        >
                            Import
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                className={classes.dialogButton}
                                disabled={isBusy}
                                appearance="secondary"
                            >
                                Close
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default FetchStatementsDialog;