import React, { useState } from 'react';
import { InternalApiClient } from 'providers/InternalApiClient';
import { IdentityApiClient } from 'providers/IdentityApiClient';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import useTenantStore from 'stores/tenantStore';
import { makeStyles } from "@fluentui/react-components";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Select,
    Option,
    Field,
    Input,
    Button,
    ProgressBar,
    Text,
    Divider,
} from "@fluentui/react-components";
import { SendFilled } from "@fluentui/react-icons";
import { useTenantContext } from '../../src/tenantContext'; // Import the context hook




const useAddClasses = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
});

const AddMemberDialog = ({ tenantId, showDialog, onCancel, onComplete }) => {

    
    const classes = useAddClasses();
    const messageStore = useMessageStore();
    const [progressMessage, setProgressMessage] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [selectedRole, setSelectedRole] = useState("Agent");
    const tenantContext = useTenantContext();
    const tenantStore = useTenantStore();

    const handleAddTenantMember = (ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        const request = {
            TenantId: tenantStore.id,
            Roles: [selectedRole], // Use the state value instead of formProps
            Name: `${formProps.firstName} ${formProps.lastName}`,
            Email: formProps.email,
            MobilePhone: formProps.phone,
            SuccessUrl: "https://app.vixolink.com/"
        };
   
        const TenantId = tenantContext.id  // Use the tenant context instead of the tenantId prop
        setIsBusy(true);
        setProgressMessage("Progress: Adding Member");
;
        // Make the API call with the request object
        TenantApiClient.post(`/api/members/${TenantId}/invite`, request)
            .catch((error) => {
                
                messageStore.addAxiosError("Failed to Send Invitation", error);
                if (onCancel) onCancel(); // Ensure onCancel is defined
            })
            .finally(() => {
                setIsBusy(false);
                setProgressMessage("Progress: Invitation Sent!");
                if (onComplete) {
                    messageStore.addMessage("Invitation sent successfully!"); // Show a success message
                    onComplete(true); // Pass `true` to indicate success
                }
            });
    };

    return (
        <Dialog open={showDialog} onOpenChange={(event, data) => onCancel(data.open)}>
            <DialogSurface>
                <form onSubmit={handleAddTenantMember}>
                    <DialogBody>
                        <DialogTitle>Invite Member to Tenant</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Field required label="Role">
                                <Select
                                    value={selectedRole}
                                    onChange={(e, data) => setSelectedRole(data.value)}
                                >
                                    <option key="owner" value="Owner">Owner</option>
                                    <option key="agent" value="Agent">Agent</option>
                                </Select>
                            </Field>
                            <Field required label="First Name">
                                <Input required type="text" name="firstName" id="firstName" />
                            </Field>
                            <Field required label="Last Name">
                                <Input required type="text" name="lastName" id="lastName" />
                            </Field>
                            <Field required label="Email Address">
                                <Input required type="email" name="email" id="email" />
                            </Field>
                            <Field label="Mobile Phone">
                                <Input type="tel" name="phone" id="phone" />
                            </Field>

                            <Divider />

                            <Text>{progressMessage}</Text>
                            {isBusy && <ProgressBar />}
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={onCancel}>Cancel</Button>
                            </DialogTrigger>
                            <Button type="submit" icon={<SendFilled />} appearance="primary">
                                Send Invitation
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};

export default AddMemberDialog;