import React, { useState, useCallback } from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import {
    PersonRegular,
    GlobePersonRegular,
    PenRegular
} from "@fluentui/react-icons";

export default function BrandingSettings() {
    const messageStore = useMessageStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [brandingData, setBrandingData] = useState({
        CompanyName: '',
        website: '',
        greetingMessage: '',
        thankYouMessage: '',
        logo: null,
        logoPreview: null
    });

    const handleImageUpload = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            setBrandingData(prev => ({
                ...prev,
                logo: file
            }));
            const reader = new FileReader();
            reader.onload = (e) => {
                setBrandingData(prev => ({
                    ...prev,
                    logoPreview: e.target.result
                }));
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const handleInputChange = useCallback((field, value) => {
        setBrandingData(prev => ({
            ...prev,
            [field]: value
        }));
    }, []);

    const convertFileToByteArray = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = () => resolve(new Uint8Array(reader.result));
            reader.onerror = (error) => reject(error);
        });
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            if (brandingData.logo) {
                const formData = new FormData();
                formData.append('file', brandingData.logo);
                const logoResponse = await TenantApiClient.post('/api/tenants/upload-logo', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            console.log(brandingData)
            const response = await TenantApiClient.post('/api/tenants/Branding', {
                CompanyName: brandingData.CompanyName,
                Website: brandingData.website,
                GreetingMessage: brandingData.greetingMessage,
                ThankYouMessage: brandingData.thankYouMessage
            });

            messageStore.addMessage('Success', 'Branding settings updated successfully');
        } catch (error) {
            messageStore.addAxiosError('Failed to update branding', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-3">
            <h1 className="text-3xl font-semibold mb-6 text-center text-blue-600">Lender Information</h1>

            <div className="flex flex-col items-center mb-8 relative">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                    id="imageUpload"
                />
                <label htmlFor="imageUpload" className="cursor-pointer">
                    {brandingData.logoPreview ? (
                        <img src={brandingData.logoPreview} alt="Logo" className="w-32 h-32 rounded-full object-cover" />
                    ) : (
                        <div className="w-32 h-32 bg-gray-200 flex items-center justify-center rounded-full">
                            <span className="text-gray-500">Upload Logo</span>
                        </div>
                    )}
                </label>
            </div>

            <div className="flex">
                <div className="flex-1 pr-8 border-r border-gray-200">
                    <div className="space-y-6">
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Lender Company Name</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <PersonRegular className="text-gray-400" />
                                </div>
                                <input
                                    value={brandingData.displayName}
                                    onChange={e => handleInputChange('displayName', e.target.value)}
                                    placeholder="Company Name"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Website</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <GlobePersonRegular className="text-gray-400" />
                                </div>
                                <input
                                    value={brandingData.website}
                                    onChange={e => handleInputChange('website', e.target.value)}
                                    placeholder="https://"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Application Greeting Message</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <PenRegular className="text-gray-400" />
                                </div>
                                <textarea
                                    value={brandingData.greetingMessage}
                                    onChange={e => handleInputChange('greetingMessage', e.target.value)}
                                    placeholder="Message"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                    rows={4}
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Application Thank You Message</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <PenRegular className="text-gray-400" />
                                </div>
                                <textarea
                                    value={brandingData.thankYouMessage}
                                    onChange={e => handleInputChange('thankYouMessage', e.target.value)}
                                    placeholder="Message"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                    rows={4}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 pt-6 border-t border-gray-200">
                <button
                    className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Updating...' : 'Update Company Profile'}
                </button>
            </div>
        </div>
    );
}